
<template>
  <div>
    <picker
      :label="$t('t.WorkQueueMainAgent')"
      class="mb-4 mx-6"
      document-types="users"
      :selected-id.sync="computedMainAgentId"
      :included-items="computedAssigned"
      :disabled="computedAssigned.length <= 0"
    />
    <v-expansion-panels
      accordion
      active-class="opened-expansion-panel"
      flat
      hover
      multiple
      v-model="displayedPanels"
    >
      <v-expansion-panel class="transparent">
        <v-expansion-panel-header class="font-weight-medium">{{$t('t.AssignedAt')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <typed-doc-picker
            :selected.sync="computedAssigned"
            :document-types="[toggleValueAssigned]"
            :readonly="readonly"
            :excludedItems="excludedRoles"
            :readonlyList="readonly"
            :filters="rolesFilters"
            multiple
            chip
            ref="assigned-picker"
          >
            <template v-slot:append>
              <topic-toggle-filter
                :toggleValue.sync="toggleValueAssigned"
                @toggle-type-click="$refs['assigned-picker'].handleClick()"
              > </topic-toggle-filter>
            </template>
          </typed-doc-picker>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="border-bottom transparent">
        <v-expansion-panel-header class="font-weight-medium">{{$t('t.VisibleBy')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <typed-doc-picker
            :selected.sync="computedVisible"
            :document-types="[toggleValueVisible]"
            :readonly="readonly"
            :readonlyList="readonly"
            multiple
            chip
            ref="visible-picker"
          >
            <template v-slot:append>
              <topic-toggle-filter
                :toggleValue.sync="toggleValueVisible"
                @toggle-type-click="$refs['visible-picker'].handleClick()"
              > </topic-toggle-filter>
            </template>
          </typed-doc-picker>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  components: {
    Picker: () => import('@/components/picker'),
    TopicToggleFilter: () => import('../topics/topic-toggle-filter'),
    TypedDocPicker: () => import('@/components/typed-doc-picker')
  },
  computed: {
    rolesFilters () {
      const roles = new IncExc()
      roles.include(['users', 'agent-supervisors'])
      return { roles: roles }
    },
    computedMainAgentId: {
      get () {
        return this.dataMainAgentId
      },
      set (v) {
        this.dataMainAgentId = v
        this.dataDocumentEmit()
      }
    },
    computedAssigned: {
      get () {
        return this.dataAssigned
      },
      set (v) {
        this.addAssigned = null
        if (this.dataAssigned.length < v.length) {
          this.addAssigned = v.filter(obj1 => !this.dataAssigned.some(obj2 => obj1.id === obj2.id && obj1.type === obj2.type))[0]
        }

        this.dataAssigned = v
        this.dataDocumentEmit()
      }
    },
    computedVisible: {
      get () {
        return this.dataVisible
      },
      set (v) {
        this.removeVisible = null
        if (this.dataVisible.length > v.length) {
          this.removeVisible = this.dataVisible.filter(obj1 => !v.some(obj2 => obj1.id === obj2.id && obj1.type === obj2.type))[0]
        }

        this.dataVisible = v
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      addAssigned: null,
      removeVisible: null,
      dataAssigned: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataMainAgentId: null,
      dataVisible: [],
      displayedPanels: [0, 1],
      excludedRoles: ['admins', 'dispute-resolvers', 'sales-reps', 'division-supervisors'],
      externalUserCacheType: CacheType.UserRef,
      toggleValueAssigned: 'users',
      toggleValueVisible: 'roles'
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        assigned: this.dataAssigned,
        mainAgentId: this.dataMainAgentId,
        visible: this.dataVisible
      }

      if (this.addAssigned) {
        if (!doc.visible.find(v => v.type === this.addAssigned.type && v.id === this.addAssigned.id)) {
          doc.visible.push(this.addAssigned)
        }
      }

      if (this.removeVisible) {
        const idx = doc.assigned.findIndex(a => a.type === this.removeVisible.type && a.id === this.removeVisible.id)
        if (idx > -1) {
          doc.assigned.splice(idx, 1)
        }
      }

      this.addAssigned = null
      this.removeVisible = null

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    readonly: Boolean,
    value: Object
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataAssigned = v?.assigned || []
        this.dataMainAgentId = v?.mainAgentId
        this.dataVisible = v?.visible || []
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.border-bottom
  border-bottom solid 1px var(--bg-plain) !important

.opened-expansion-panel > .v-expansion-panel-header
  border var(--bg-translucid)
  min-height 48px
</style>
